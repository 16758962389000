<template>
  <v-container v-show="isReady" class="general">
    <page-title :component="'GroupSignup'">
      <template v-slot:actions>
        <LanguageSwitcher />
      </template>
    </page-title>
    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !groupPlugins.length"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t("signup_list_page.nothing_created") }}
        </v-flex>
      </v-layout>
    </transition>
    <v-card
      v-if="groupPlugins && groupPlugins.length"
      class="px-4 py-3 border-radius"
    >
      <v-layout
        v-for="(plugin, i) in groupPlugins"
        :key="`plugin-${i}`"
        :style="{ width: '100%' }"
        class="row wrap align-center"
      >
        <v-flex v-if="i !== 0" class="xs12 my-3">
          <v-divider />
        </v-flex>
        <v-flex class="xs12">
          <v-layout class="row align-center">
            <v-flex class="xs12">
              <a class="sw-accent" @click="editplugin(plugin.id)">
                {{ plugin.name }}
              </a>
            </v-flex>
            <v-btn icon class="ma-0" @click.stop="deleteGroupPlugin(plugin.id)">
              <font-awesome-icon class="sw-accent" icon="trash" />
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
    <AppSpeedDial
      @click="$router.push({ name: 'signups_create' })"
      :hint="$t('signup_list_page.speed_dial_hint')"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  data: () => ({
    isReady: false,
    isLoading: false,
    groupPlugins: [],
    perPage: 100,
  }),
  computed: {
    isTemplateGroup() {
      return this.$store.getters.activeGroup.is_template;
    },
    groupId() {
      return this.$route.params.group_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
  },
  components: {
    LanguageSwitcher,
  },
  watch: {
    appContentLanguage: {
      handler() {
        this.listGroupPlugins("signup");
      },
    },
  },
  mounted() {
    this.listGroupPlugins("signup");
  },
  methods: {
    editplugin(groupPluginId) {
      if (!groupPluginId) return;

      this.$router
        .push({
          name: "signups_edit",
          params: {
            group_plugin_id: groupPluginId,
          },
        })
        .catch(() => {});
    },
    listGroupPlugins(prefix) {
      this.isLoading = true;

      const params = [
        this.groupId,
        {
          prefix: prefix,
          with_attributes: 1,
          lang: this.appContentLanguage,
          per_page: this.perPage,
        },
      ];

      this.$api.groupPlugins.list(...params).then((response) => {
        if (!response || !response.data.data) {
          this.isReady = true;
          this.isLoading = false;
          return;
        }

        if (!this.isTemplateGroup && response.data.data.length === 1) {
          this.editplugin(response.data.data[0].id);
        } else {
          this.isReady = true;
          this.isLoading = false;
          this.groupPlugins = response.data.data;
        }
      });
    },
    async deleteGroupPlugin(id) {
      if (!id) return;

      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("signup_wizard.delete_confirmation_text"),
        this.$t("no"),
        this.$t("yes"),
      );

      if (!isConfirmed) return;

      this.$api.groupPlugins
        .delete(this.groupId, id)
        .then((res) => {
          if (res.status === 200) {
            this.listGroupPlugins("signup");
          }
        })
        .catch((error) => this.errorMessageShow(error));
    },
  },
};
</script>

<style scoped></style>
